// Mobile menu activator and intersection observer for animations.


document.addEventListener("DOMContentLoaded", function() {
  check_animations();  
});


// ------------------------------------------------------------------------  menus 

const mob_activator = <HTMLElement>document.getElementById("mobile_menu_activator");
const mob_close = <HTMLElement>document.getElementById("mobile_menu_close");
const mob_menu = <HTMLElement>document.getElementById("mobile_menu");

mob_activator.addEventListener("click", () => toggle_mobile_menu());
mob_close.addEventListener("click", () => toggle_mobile_menu());

function toggle_mobile_menu() {
  mob_menu.classList.toggle("mobile_menu_open");
}


// ------------------------------------------------- Intersection Animation Listener ------		
// Reverse animations when page 
function check_animations() {

  const animElem = document.querySelectorAll(".anim-elem");

  const divAnimWatcher = new IntersectionObserver(function (entries, observer) {
      entries.forEach(function (entry) {
          if (entry.isIntersecting) {
              var elem = entry.target;
              setTimeout(function () {
                  elem.classList.remove("anim-pause");
                  elem.classList.add("anim-play");
              }, 500);
              // divAnimWatcher.unobserve(elem);
          } else {
              var elem = entry.target;
              if (elem.classList.contains("anim-rewind")) {
                elem.classList.remove("anim-play");
                elem.classList.add("anim-pause");
              }
          }
      });
  });
  animElem.forEach(function (a) {
      a.classList.add("anim-pause");
      divAnimWatcher.observe(a);
  });
}


function logger(s:string) {
console.log('%c' + s , 'background:#DEDEFA;color:#333');
}