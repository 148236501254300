import Splide from '@splidejs/splide';
import './common.ts';

document.addEventListener( 'DOMContentLoaded', function () {
  
  const splide = new Splide( '.splide',
  {
    type: 'fade',
    speed: 4000,
    autoplay: true,
    interval: 3000,
    pauseOnHover: false,
    arrows: false,
    pagination: false,
    lazyLoad: 'sequential',
    rewind: true,

  });

  splide.mount();
  

  console.log('Splide mounted');
});